import React, { useState, Fragment, useEffect, useContext } from "react";

import firebase from "../firebase";

import AppContext from "../provider/AppContext";

export default function Reihe() {
  const { zahlg, setZahlg, kati, setKati, splr, setSplr, gewaehltnam, spielc } =
    useContext(AppContext);

  const [antwort, setAntwort] = useState("");
  const [nant, setNant] = useState(0);
  const [ende, setEnde] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allegleich, setAlleggleich] = useState(false);
  const [losarr, setLosarr] = useState([]);
  const [winner, setWinner] = useState([1002000000, 2220000000000]);
  const [btnloesung, setBtnloesung] = useState([1001, "Lösung"]);
  const [eingloesung, setEingloesung] = useState([1003, 234]);
  const [schaetzwin, setSchaetzwin] = useState(0);
  const [nochmal, setNochmal] = useState(false);
  const [rangstate, setRangstate] = useState([]);

  const ref = firebase.firestore();

  const katarr = [
    [
      <h4>01. Januar 2021</h4>,
      <div>
        <p>
          Am 01. Januar ist der Brexit vollzogen. Wie viele EU-Mitgliedsstaaten
          gibt es jetzt?
        </p>
        {allegleich ? null : (
          <div>
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 27)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>Lösung: Die Anzahl der EU-Staaten ist jetzt 27.</p>,
    ],
    [
      <h4>05. Januar 2021</h4>,
      <div>
        <p>
          Die Regierung verlängert den bundesweiten Lockdown zunächst bis Ende
          Januar. Danach wurde der Lockdown mehrfach verlängert. In welchem
          Monat endete der Lockdown?
        </p>{" "}
        {allegleich
          ? null
          : ["März", "April", "Mai", "Juni"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Mai")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Der im November 2020 begonnene Lockdown endete im Mai 2021.</p>,
    ],
    [
      <h4>06. Januar 2021</h4>,
      <div>
        <p>
          Anhänger von Donald Trump stürmen das Kapitol in Washington D.C.
          Schätze wie viele Menschen beteiligten sich direkt an dem Sturm auf
          das Gebäude?
        </p>
        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />
            <button onClick={() => negi(nant, 800)}>Absenden</button>{" "}
          </div>
        )}
      </div>,
      <p>
        Lösung: Es beteiligten sich rund 800 Menschen direkt an der Erstümung
        des Gebäudes.
      </p>,
    ],
    [
      <h4>06. Januar 2021</h4>,
      <div>
        <p>
          Die EU-Kommission lässt einen zweiten von bisher 4 Corona-Impfstoffen
          zu. Welcher Impfstoff wurde als zweites in der EU zugelassen?
        </p>{" "}
        {allegleich
          ? null
          : ["Astra Zeneca", "Biontech", "JohnsonJohnson", "Moderna"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Moderna")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>
        Moderna war der zweite in der EU zugelassene Impfstoff nach Biontech.
        Danach folgten Astra Zeneca und dann Johnson + Johnson.
      </p>,
    ],
    [
      <h4>16. Januar 2021</h4>,
      <div>
        <p>
          Armin Laschet wird zum Parteivorsitzenden der CDU gewählt. Wer hatte
          dieses Amt direkt vor Armin Laschet inne?
        </p>
        {allegleich
          ? null
          : [
              "Angela Merkel",
              "Norbert Röttgen",
              "Annegret Kramp-Karrenbauer",
              "Peter Altmeier",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Annegret Kramp-Karrenbauer")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Annegret Kramp-Karrenbauer war vor Armin Laschet Parteivorsitzende der
        CDU. Sie hatte das Amt von Angela Merkel übernommen.
      </p>,
    ],
    [
      <h4>Januar 2021</h4>,
      <div>
        <p>
          Anfang des Jahres bestimmt der Mangel an Corona-Impfstoff die
          Schlagzeilen in Deutschland. Beim Thema "Impfvordrängler" wird heiß
          diskutiert. Im Fall des parteilosen Bürgermeisters Bernd Wiegand, der
          sich im Januar mit Biontech impfen lässt, ermittelt sogar die
          Staatsanwaltschaft. In welcher Stadt war Wiegand Bürgermeister?
        </p>{" "}
        {allegleich
          ? null
          : ["Erfurt", "Halle", "Freiberg", "Potsdam"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Halle")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Bernd Wiegand war Bürgermeister in Halle, wurde mit seiner frühen
        Impfung Deutschlandweit bekannt, und war deshalb sogar bei Markus Lanz
        eingeladen.
      </p>,
    ],
    [
      <h4>17. Januar 2021</h4>,
      <div>
        <p>
          Der Russische Oppositionspolitiker Alexej Nawalny fliegt am 17. Januar
          zurück nach Russland und wird dort festgenommen. Was war zuvor der
          Grund seines Aufenthalts in Deutschland?
        </p>{" "}
        {allegleich
          ? null
          : [
              "Politisches Asyl",
              "Geschäftsreise",
              "Medizinische Behandlung",
              "Urlaub",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Medizinische Behandlung")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Aufgrund einer Vergiftung hatte er sich in Deutschland zur medizinischen
        Behandlung aufgehalten.
      </p>,
    ],
    [
      <h4>20. Januar 2021</h4>,
      <div>
        <p>
          Joe Biden wird als bisher ältester US-Präsident vereidigt. Wie alt ist
          er bei seiner Vereidigung?
        </p>{" "}
        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 78)}>Absenden</button>{" "}
          </div>
        )}
      </div>,
      <p>
        Bei seiner Vereidigung zum US-Präsidenten war Joe Biden 78 Jahre alt.
      </p>,
    ],
    [
      <h4>20. Januar 2021</h4>,
      <div>
        <p>
          Der wievielte US-Präsident ist Joe Biden bei seiner Vereidigung am 20.
          Januar?
        </p>{" "}
        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 46)}>Absenden</button>{" "}
          </div>
        )}
      </div>,
      <p>Joe Biden ist der 46. Präsident der USA</p>,
    ],
    [
      <h4>20. Januar 2021</h4>,
      <div>
        <p>
          Wie viele noch lebenden US-Präsidenten waren bei Joe Bidens
          Vereidigung am 20. Januar nicht abwesend?
        </p>
        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 2)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Zwei noch lebende US-Präsidenten waren abwesend: Donald Trump, und aus
        gesundheitlichen Gründen Jimmy Carter.
      </p>,
    ],
    [
      <h4>26. Januar 2021</h4>,
      <div>
        <p>
          Italiens Ministerpräsident reicht seinen Rücktritt ein. Wie hieß er?
        </p>
        {allegleich
          ? null
          : ["Mario Monti", "Mario Draghi", "Guiseppe Conte", "Agnelli"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Guiseppe Conte")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>
        Guiseppe Conte. Er war seit 1. Juni 2018 Italiens Ministerpräsident
        gewesen.
      </p>,
    ],
    [
      <h4>Januar 2021</h4>,
      <div>
        <p>
          Wer hatte am Anfang des Jahres die positivsten Erwartungen für 2021
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            {splr.map((item, index) => (
              <div key={item}>
                <button onClick={() => spielerClick(item)}>{item}</button>
              </div>
            ))}
          </div>
        )}
      </div>,
      <p>leer</p>,
    ],
    [
      <h4>01. Februar 2021</h4>,
      <div>
        <p>
          Militärputsch in Myanmar. Das Militär verhängt einen einjährigen
          Ausnahmezustand, der im Sommer bis August 2023 verlängert wird.
          Welches dieser Länder ist kein Nachbarland von Myanmar?
        </p>
        {allegleich
          ? null
          : [
              "Bangladesch",
              "China",
              "Laos",
              "Kambodscha",
              "Thailand",
              "Indien",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Kambodscha")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Kambodscha hat keine Grenze mit Myanmar.</p>,
    ],
    [
      <h4>09. Februar 2021</h4>,
      <div>
        <p>
          Obwohl er nicht mehr im Amt ist, beginnt das zweite
          Amtsenthebungsverfahren gegen Donald Trump. Mit welcher Begründung?
        </p>

        {allegleich
          ? null
          : [
              "Verantwortung Sturm des Kapitols",
              "Vermengung von Amt und persönlichen Geschäften",
              "Instrumentalisierung von Ukraine-Militärhilfen gegen Joe Biden",
              "Weigerung Steuererklärung zu veröffentlichen",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() =>
                    btnclick(item, "Verantwortung Sturm des Kapitols")
                  }
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Das zweite Amtsenthebungsverfahren wurde wegen des Vorwurfs der
        Mitverantowrtung für den Sturm auf das Kapitol angestrengt.
      </p>,
    ],
    [
      <h4>18. Februar 2021</h4>,
      <div>
        <p>
          Der US-Mars-Rover "Perseverance" landet auf dem Mars. Was gelang im
          Verlauf dieser Mission zum ersten Mal überhaupt?
        </p>

        {allegleich
          ? null
          : [
              "Tonaufnahmen vom Mars",
              "Rücksendung von Bodenproben zur Erde",
              "Errichtung des Fundaments einer späteren Marssiedlung",
              "Live-Stream vom Mars",
              "Videoaufzeichnungen in Farbe",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Tonaufnahmen vom Mars")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Tatsächlich wurden zum ersten Mal Tonaufnahmen vom Mars gemacht. Zu
        hören sind Windgeräusche vom Mars und Geräusche des Roboters. Zudem
        gelang es das erste mal künstlich Sauerstoff herzustellen (5 Gramm
        mittels Elektrolyse). Zudem wurde das erste Mal ein Hubschrauber auf
        einem anderen Planeten geflogen.
      </p>,
    ],
    [
      <h4>03. März 2021</h4>,
      <div>
        <p>
          Die Abgeordneten der Partei Fidesz treten aus der EVP-Fraktion im
          Europäischen Parlament aus. Die Regierungspartei welchen Landes ist
          das?
        </p>

        {allegleich
          ? null
          : ["Finnlad", "Polen", "Ungarn", "Slowakei"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Ungarn")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Fidesz ist die Regierungspartei von Ungarn.</p>,
    ],

    [
      <h4>03. März 2021</h4>,
      <div>
        <p>
          Geschäftsräume und Wohnungen des Bundestagsabgeordneten Axel Fischer
          (CDU) werden durchsucht. Welcher Verdacht stand im Raum?
        </p>

        {allegleich
          ? null
          : [
              "Geld aus Aserbaidschan",
              "Illegale Maskendeals",
              "Steuertricks in Steueroasen",
              "Illegale Parteispenden",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Geld aus Aserbaidschan")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Er stand im Verdacht Geld für Gegenleistungen aus Aserbaidschan erhalten
        zu haben.
      </p>,
    ],
    [
      <h4>05. März 2021</h4>,
      <div>
        <p>
          Die Masken-Deals des jungen Bundestagsabgeordneten Nikolas Löbel (CDU)
          aus Mannheim werden bekannt. Zunächst versucht er sich zu
          rechtfertigen, kündigt dann die Rückgabe des Mandats zu Ende August
          (weniger als 1 Monat vor Ablauf der Wahlperiode) an. Wie viele Tage
          hält er tatsächlich dem öffentlichen Druck stand, bevor er alle Ämter
          und Mandate zurückgibt?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Tage"
            />{" "}
            <button onClick={() => negi(nant, 3)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Er hielt dem Druck der Öffentlichkeit und seiner Partei nur 3 Tage
        stand. Heikel war, dass nur wenige Tage später die Landtagswahl in
        Baden-Württemberg war.
      </p>,
    ],
    [
      <h4>07. März 2021</h4>,
      <div>
        <p>
          Der Vize-Fraktionsvorsitzende der CDU/CSU-Fraktion Georg Nüßlein muss
          aus der Fraktion austreten, weil seine Maskengeschäfte während der
          Pandemie aufgedeckt werden. Wie hoch war Nüßleins Provision aus dem
          Deal laut Presseberichten? Gib den vollen Betrag ohne Kommas ein.
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Geschätzter Betrag"
            />{" "}
            <button onClick={() => negi(nant, 1200000)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Georg Nüßlein soll laut Presseberichten iInsgesamt 1,2 Millionen Euro an
        persönlichem Pandemiegeschäft kassiert haben.
      </p>,
    ],

    [
      <h4>07. März 2021</h4>,
      <div>
        <p>
          Der Papst besucht ein Land im Nahen Osten, das zuvor noch nie ein
          Papst besucht hatte. Welches?
        </p>

        {allegleich
          ? null
          : [
              "Jordanien",
              "Israel",
              "Ägypten",
              "Irak",
              "Iran",
              "Saudi-Arabien",
              "Yemen",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Irak")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Der Papst besucht auf seiner Reise Arbil, Bagdad, in Nadschaf trifft er
        den shiitischen Großajatolla Ali al-Sistani, Karakosch und Mossul.{" "}
      </p>,
    ],
    [
      <h4>17. März 2021</h4>,
      <div>
        <p>
          Mark Rutte gewinnt mit seiner Partei VVD die niederländische
          Parlamentswahl. Welche Partei wäre dazu das deutsche Pendant?
        </p>

        {allegleich
          ? null
          : ["SPD", "CDU", "FDP", "Grüne", "AfD"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "FDP")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Die VVD der Niederlande entspricht in Deutschland der FDP.</p>,
    ],
    [
      <h4>14. März 2021</h4>,
      <div>
        <p>
          Die ersten 2 von insgesamt 5 Landtagswahlen in 2021 finden statt. In
          welchem dieser Bundesländern wurde 2021 NICHT der Landtag gewählt?
        </p>

        {allegleich
          ? null
          : [
              "Baden-Württemberg",
              "Sachsen-Anhalt",
              "Thüringen",
              "Mecklenburg-Vorpommern",
              "Rheinland-Pfalz",
              "Berlin",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Thüringen")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Die richtige Antwort ist Thüringen. 2021 wurde in Baden-Württemberg,
        Rheinland-Pfalz, Sachsen-Anhalt, Mecklenburg-Vorpommern, Berlin gewählt.
      </p>,
    ],
    [
      <h4>24. März 2021</h4>,
      <div>
        <p>
          Der Welt-Handel gerät ins Stocken, weil ein 400 Meter langes
          Containerschiff im Suez-Kanal auf Grund läuft. Wie heißt das Schiff?
        </p>

        {allegleich
          ? null
          : [
              "COSCO Shipping Universe",
              "Madrid Maersk",
              "CMA CGM Concorde",
              "MSC Mina",

              "Ever Given",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Ever Given")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Am 29. März konnte die "Ever Given" wieder freigelegt werden und der
        Schiffsverkehr im Suez-Kanal wieder aufgenommen werden. Über die Ursache
        des Unglücks gibt es Streit und Schadenersatzforderungen.
      </p>,
    ],
    [
      <h4>29. März 2021</h4>,
      <div>
        <p>
          Prozessauftakt gegen Derek Chauvin, der 2020 George Floyd getötet
          hatte. In welcher US-Stadt war die Tat geschehen?
        </p>

        {allegleich
          ? null
          : ["Minneapolis", "Chicago", "Seattle", "Portland", "San Diego"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Minneapolis")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>Minneapolis (Bundesstaat Minnesota).</p>,
    ],
    [
      <h4>06. April 2021</h4>,
      <div>
        <p>
          Beim Besuch des Präsidenten des Europäischen Rats (Charles Michel) und
          der Präsidentin der Europäischen Kommission (Ursula von der Leyen)
          beim Türkischen Präsident Erdogan in Ankara kommt es zu einem Eklat.
          Warum?
        </p>

        {allegleich
          ? null
          : [
              "Erdogan verwehrte von der Leyen den Handschlag",
              "Erdogan hielt von der Leyen für die Übersetzerin",
              "Von der Leyen wirkte betrunken",
              "Charles Michel hatte keine Maske dabei",
              "Von der Leyen musste an der Seite auf einem Sofa sitzen anstatt neben Erdogan",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() =>
                    btnclick(
                      item,
                      "von der Leyen musste an der Seite auf einem Sofa sitzen anstatt neben Erdogan"
                    )
                  }
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Charles Michel, der protokollarisch über Ursula von der Leyen steht,
        durfte direkt neben Erdogan auf einem Sessel platz nehmen. Von der Leyen
        musste auf eine Couch daneben, gegenüber dem Türkischen Außenminister
        platznehmen, und war darüber sichtlich irritiert.
      </p>,
    ],
    [
      <h4>09. April 2021</h4>,
      <div>
        <p>
          Prinz Philip, der Ehemann der Englischen Königin stirbt im Alter von
          99 Jahren. Er wurde in Griechenland geboren und war aufgrund seiner
          Eltern nicht nur in der Thronfolge Griechenlands, sondern noch eines
          anderen Europäischen Landes. Von welchem?
        </p>

        {allegleich
          ? null
          : [
              "Großbritannien",
              "Deutschland",
              "Schweden",
              "Dänemark",
              "Norwegen",
              "Niederlande",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Dänemark")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Prinz Philip war per Geburt Prinz von Griechenland (Nachfahre von
        Georgiois I) und Dänemark (Nachfahre von Christian IX).
      </p>,
    ],
    [
      <h4>18. April 2021</h4>,
      <div>
        <p>
          12 Fußballvereine wollen eine 'Superliga' gründen. Der Plan wird dann
          auf Eis gelegt. Welcher dieser Teilnehmervereine mit
          Startplatzgarantie in der Superleague hat aktuell die höchste
          Verschuldung?
        </p>

        {allegleich
          ? null
          : [
              "FC Barcelona",
              "Real Madrid",
              "Juventus Turin",
              "Tottenham Hotspur",
              "AC Mailand",
              "Inter Mailand",
              "FC Chelsea",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "FC Chelsea")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Der FC Chelsea hat die höchsten Schulden mit 1,51 Milliarden Euro.
        Danach folgen Tottenham Hotspur (1,28 Milliarden), FC Barcelona (1,17
        Milliarden), Real Madrid (901 Millionen Euro), Inter Mailand (630
        Millionen), Manchester United (494 Millionen).
      </p>,
    ],
    [
      <h4>19. April 2021</h4>,
      <div>
        <p>
          Annalena Baerbock wird Kanzlerkandidatin der Grünen für die
          Bundestagswahl. Bei den Grünen gibt es traditionell den "Realo" und
          den linken "Fundi" Flügel. Welchem wird Annalena Bearbock zugerechnet?
        </p>

        {allegleich
          ? null
          : ["Realo", "Fundi"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Realo")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Sowohl Annalena Baerbock, als auch ihr Co-Vorsitzender Robert Habeck
        werden dem Realo-Flügel der Grünen zugerechnet.
      </p>,
    ],
    [
      <h4>20. April 2021</h4>,
      <div>
        <p>
          Armin Laschet wird Kanzlerkandidat von CDU und CSU. Welchen Beruf
          hatte Laschet vor seiner politischen Karriere?
        </p>

        {allegleich
          ? null
          : [
              "Landesgeschäftsführer der Caritas NRW",
              "Rechtsanwalt",
              "Lehrer",
              "Journalist",
              "Unternehmensberater",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Journalist")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Vor seiner politischen Laufbahn war Armin Laschet Journalist.</p>,
    ],
    [
      <h4>20. April 2021</h4>,
      <div>
        <p>
          Markus Söder unterliegt im unionsinternen Machtkampf um die
          Kanzlerkandidatur Armin Laschet. Welchen Beruf hatte Söder vor seiner
          politischen Karriere?
        </p>

        {allegleich
          ? null
          : [
              "Jurist im Unternehmen seiner Frau",
              "Rechtsanwalt",
              "Lehrer",
              "Journalist ",
              "Unternehmensberater",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Journalist ")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Vor seiner Tätigkeit als Berufspolitiker war Markus Söder Journalist.
      </p>,
    ],
    [
      <h4>22. April 2021</h4>,
      <div>
        <p>
          Bekannte Schauspielerinnen und Schauspieler wie Heike Makatsch, Nadja
          Uhl, Ulrich Tukur und Jan Josef Liefers melden sich mit ironischen,
          1-Minütigen Youtube-Videos zur Corona-Politik kritisch zu Wort. Jan
          Josef Liefers schlägt daraufhin besonders viel Kritik entgegen. Was
          wollte er als Konsequenz aus der Kritik tun?
        </p>

        {allegleich
          ? null
          : [
              "Medizin studieren",
              "Mit Christian Drosten sprechen",
              "Praktikum auf Intensivstation machen",
              "Sich öffentlich impfen lassen",
              "Seine Rolle im Tatort aufgeben",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() =>
                    btnclick(item, "Praktikum auf Intensivstation machen")
                  }
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Zunächst wollte Liefers auf einer Essener Intensivstation helfen,
        erhielt dort aber eine Abfuhr. Später besuchte er für ein 1-Tägiges
        Praktikum eine Intensivstation und berichtete darüber in der
        Bild-Zeitung.
      </p>,
    ],
    [
      <h4>25. April 2021</h4>,
      <div>
        <p>
          Die verschobene, unter Corona-Bedingungen stattfindende
          Oscar-Verleihung ist ein Quoten-Desaster: nicht einmal 10 Millionen
          Zuschauer schauen sich die Show im US-Fernsehen an. Das zweite Mal in
          der Geschichte der Oscars gewinnt eine Frau (Cloé Zhao) den Oscar für
          die beste Regie. Ihr Film gewinnt auch den Oscar in der Kategorie
          "Bester Film". Wie heißt der Film?
        </p>

        {allegleich
          ? null
          : [
              "The Father",
              "Nomadland",
              "Promising Young Woman",
              "Judas and the Black Messiah",
              "Mank",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Nomadland")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Der Low-Budget und Independent-Film "Nomadland gewinnt" die Kategorien
        "Bester Film" und "Beste Regie", zudem bekommt Frances McDormand für
        ihre Rolle in dem Film den Oscar für die "Beste Hauptdarstellerin".
      </p>,
    ],
    [
      <h4>06. Mai 2021</h4>,
      <div>
        <p>
          Die Grünen sind in Umfragen zur Bundestagswahl stärkste Kraft. Wie
          viele Prozentpunkte Vorsprung haben die Grünen laut Umfragen an diesem
          Tag auf die SPD?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Prozentpunkte"
            />{" "}
            <button onClick={() => negi(nant, 12)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Laut Infratest Dimap standen die Grünen am 06. Mai bei 26%, die SPD bei
        14%. Bei der Bundestagswahl im September war es umgekehrt.
      </p>,
    ],
    [
      <h4>10. Mai 2021</h4>,
      <div>
        <p>
          Die Partei "die Linke" nominiert zwei Spitzenkandidaten für die
          Bundestagswahl. Wer ist Spitzenkandidat/in neben Janine Wissler?
        </p>

        {allegleich
          ? null
          : [
              "Gregor Gysi",
              "Dietmar Bartsch",
              "Susanne Hennig-Wellsow",
              "Bodo Ramelow",
              "Petra Pau",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Dietmar Bartsch")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Dietmar Bartsch war mit Janine Wissler Spitzenkandidat für die
        Bundestagswahl.
      </p>,
    ],
    [
      <h4>14. Mai 2021</h4>,
      <div>
        <p>
          Der zweite Marsrover des Jahres 2021 landet auf dem Mars. Diesmal
          handelt es sich nicht um ein Prejekt der USA. Wer hat am 14. Mai ein
          Fahrzeug auf dem Mars landen lassen?
        </p>

        {allegleich
          ? null
          : [
              "Space-X",
              "ESA (Europäische Weltraum Agentur)",
              "Indien",
              "China",
              "Israel",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "China")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        China gelingt die erste Mission dieser Art mit dem Roboterauto
        "Zhurong". Ausgestattet mit Kameras, Radar, Wetterstation und mehr legt
        in den folgenden Monaten hunderte Kilometer auf dem Mars zurück.
      </p>,
    ],
    [
      <h4>19. Mai 2021</h4>,
      <div>
        <p>
          Familienministerin Franziska Giffey tritt wegen Plagiatsvorwürfen
          bezüglich ihrer Doktorarbeit zurück. Wie viele Minister/innen mussten
          während Angela Merkels Amtszeit wegen einer plagiierter Doktorarbeit
          zurückgetreten?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Geschätzte Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 3)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        3 Minister mussten zurücktreten. Theodor zu Guttenberg, Annette Shavan
        und Franziska Giffey.
      </p>,
    ],
    [
      <h4>21. Mai 2021</h4>,
      <div>
        <p>
          In Indien wütet eine neue, ansteckendere Corona-Variante. Die WHO geht
          dazu über, neue Mutationen mit Griechischen Buchstaben zu bezeichnen.
          Wie heißt nach dieser Benennung die "indische" Variante?
        </p>

        {allegleich
          ? null
          : ["Alpha", "Delta", "Beta", "Gamma", "Zeta"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Delta")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Delta ist die korrekte Antwort.</p>,
    ],
    [
      <h4>23. Mai 2021</h4>,
      <div>
        <p>
          Ein Furchtbares Unglück ereignet sich in Italien, als das Seil einer
          Seilbahngondel reißt, und mit 15 Menschen an Bord abstürzt. Nur ein
          fünfjähriger Junge überlebt. Die Staatsanwaltschaft ermittelt wegen
          Fahrlässigkeit, weil eine Notbremse außer Kraft gesetzt worden war.
        </p>
        {allegleich ? null : null}
      </div>,
      <p> </p>,
    ],
    [
      <h4>26. Mai 2021</h4>,
      <div>
        <p>
          Deutschland einigt sich Entschädigungsleistungen zu zahlen für
          Kolonial-Verbrechen zwischen 1904 und 1908 an den Herero. An welchen
          afrikanischen Staat wird die Entschädigung geleistet?
        </p>

        {allegleich
          ? null
          : ["Tansania", "Gabun", "Tschad", "Togo", "Namibia"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Namibia")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>Die Einigung wurde mit Namibia geschlossen.</p>,
    ],
    [
      <h4>27. Mai 2021</h4>,
      <div>
        <p>
          Das Finale von Germany's Next Topmodel findet wegen Corona zum zweiten
          Mal ohne Studio-Publikum statt. Die Staffel 2021 war so divers wie
          noch nie. Wer gewann die GNTM-Staffel 2021?
        </p>

        {allegleich
          ? null
          : [
              "Romina, die kleiner als 1,70 ist",
              "Alex, Transgender",
              "Dascha, Curvy-Model",
              "Soulin, die mit Ihrer Familie aus Syrien kam",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Alex, Transgender")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Alex, 23 Jahre alt aus Köln, gewann die diesjährige Staffel.</p>,
    ],
    [
      <h4>13. Juni 2021</h4>,
      <div>
        <p>
          Nach 12-jähriger Amtszeit wird Benjamin Netanjahu als israelischer
          Ministerpräsident abgelöst. Wie heißt der neue israelische
          Premierminister?
        </p>

        {allegleich
          ? null
          : [
              "Jair Lapid",
              "Naftali Bennett",
              "Jitzchak Herzog",
              "Tzipi Livni",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Naftali Bennett")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Naftali Bennett löste Netanjahu als Ministerpräsident ab. Zuvor war er
        Verteidigungsminister.
      </p>,
    ],
    [
      <h4> 09. Juni 2021</h4>,
      <div>
        <p>
          Joe Biden besucht zum ersten mal als Präsident Europa. Welches dieser
          Länder besucht er während dieser ersten Reise NICHT?
        </p>

        {allegleich
          ? null
          : ["Belgien", "Großbritanien", "Schweiz", "Frankreich"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Frankreich")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>
        Joe Biden fuhr bei dieser Reise zuerst zum G7-Treffen in Großbritanien,
        dann zum Besuch der NATO nach Brüssel und dann weiter in die Schweiz zu
        einem Treffen mit Präsident Vladimir Putin. In Frankreich war er nicht.
      </p>,
    ],

    [
      <h4> 11. Juni 2021</h4>,
      <div>
        <p>
          Die Fußball-Europameisterschaft beginnt. Gegen welchen Gegner scheidet
          Deutschland im Achtelfinale aus?
        </p>

        {allegleich
          ? null
          : ["England", "Frankreich ", "Ungarn", "Portugal", "Italien"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "England")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>Gegen England war dieses Mal schon im Achtelfinale schluss.</p>,
    ],

    [
      <h4> 25. Juni 2021</h4>,
      <div>
        <p>
          In der Würzburger Innenstadt tötet ein verwirrter Terrorist 3 Frauen.
        </p>
        {allegleich ? null : null}
      </div>,
      <p> </p>,
    ],
    [
      <h4> 14. Juli 2021</h4>,
      <div>
        <p>
          Das polnische Verfassungsgericht urteilt, dass Polen nicht an
          Anordnungen der Europäischen Justiz gebunden ist. In welchem Jahr
          wurde Polen EU-Mitglied?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Jahr"
            />{" "}
            <button onClick={() => negi(nant, 2004)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>Polen trat am 01. Mai 2004 der Europäischen Union bei.</p>,
    ],
    [
      <h4> 14. Juli 2021</h4>,
      <div>
        <p>
          Schwere Unwetter mit heftigen Überschwemmungen in Nordrhein-Westfalen
          und Rheinland-Pfalz. Es Sterben mindestens 184 Menschen in Deutschland
          und 41 Menschen in Belgien. Wie hoch wird der finanzielle
          Gesamtschaden der Flut in Deutschland geschätzt?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Geschätzter Betrag in Euro"
            />{" "}
            <button onClick={() => negi(nant, 17700000000)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Der Gesamtschaden in Deutschland wird auf 17,7 Milliarden Euro (ca. 20
        Mrd. US-Dollar) geschätzt. Die Lösung ist also 17700000000 Euro
      </p>,
    ],
    [
      <h4> 23. Juli 2021</h4>,
      <div>
        <p>
          Die um ein Jahr verschobenen Olympischen Spiele werden in Tokio
          eröffnet. Im Mediallenspiegel belegte die USA Platz 1, gefolgt von
          China und Japan. Welchen Platz im Medaillenspiegel erreicht
          Deutschland?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Platz im Medaillenspiegel"
            />{" "}
            <button onClick={() => negi(nant, 9)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>Deutschland belegte im Medaillenspiegel Platz 9.</p>,
    ],
    [
      <h4> 27. Juli 2021</h4>,
      <div>
        <p>
          Schwere Explosion im Chemiepark in Leverkusen. 7 Tote und viele
          Verletzte.
        </p>
        {allegleich ? null : null}
      </div>,
      <p> </p>,
    ],

    [
      <h4> 15. August 2021</h4>,
      <div>
        <p>
          Die Taliban erobern Kabul, Hauptstadt Afghanistans. Wie viele
          Einwohner hat Kabul?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Einwohner"
            />{" "}
            <button onClick={() => negi(nant, 4300000)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>Kabul hat ca. 4 300 000, also 4,3 Millionen Einwohner.</p>,
    ],

    [
      <h4> 16. September 2021</h4>,
      <div>
        <p>
          Australien storniert einen U-Boot-Auftrag in Frankreich und gibt ein
          Verteidigungsbündnis mit den USA und Großbritannien bekannt. Die
          Franzosen sind verärgert. Welchen finanziellen Umfang in Euro hätte
          das Geschäft gehabt?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Einwohner"
            />{" "}
            <button onClick={() => negi(nant, 80000000000)}>Absenden</button>
          </div>
        )}
      </div>,
      <p>
        Der Auftrag hatte ein Volumen von bis zu 90 Milliarden US-Dollar. Das
        sind ca. 80 000 000 000 Euro.
      </p>,
    ],
    [
      <h4>18. September 2021</h4>,
      <div>
        <p>
          Ein Student, der als Aushilfe in einer Tankstelle in Idar-Oberstein
          arbeitet, wird erschossen, weil er einen Kunden auf die Maskenpflicht
          hinweist.
        </p>
        {allegleich ? null : null}
      </div>,
      <p> </p>,
    ],
    [
      <h4>20. September 2021</h4>,
      <div>
        <p>
          Der Dax wird von 30 auf 40 Unternehmen erweitert. Wie viele der
          größten Dax-Unternehmen sind zusammen so viel Wert wie der US-Konzern
          Apple?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Einwohner"
            />{" "}
            <button onClick={() => negi(nant, 40)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>Alle Dax Unternehmen, also 40.</p>{" "}
        <p>
          Allerdings reicht auch das nicht, denn die Dax Unternehmen haben
          zusammen nur eine Marktkapitalisierung von ungefähr 1,7 Billionen
          Euro. Apple allein ist mit ca. 2,5 Billionen Euro bewertet.
        </p>
      </div>,
    ],
    [
      <h4>26. September 2021</h4>,
      <div>
        <p>
          Bundestagswahl: SPD wird stärkste Kraft. Zusammen kommen SPD und CDU
          nur noch auf knapp 50% der Stimmen. Warum ist die Partei "die Linke"
          weiterhin als Fraktion im Bundestag, obwohl sie nur 4,9% der Stimmen
          bekam?
        </p>

        {allegleich
          ? null
          : [
              "Die anderen Fraktionen haben dem zugestimmt",
              "Weil 3 Direktmandate gewonnen wurden",
              "Wegen niedriger Wahlbeteiligung reichen 4,9%",
              "Wegen einer Quotenregelung für Ostdeutschland",
              "Wegen Ausgleichsmandaten ist die Anzahl der Abgeordneten über 5%",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() =>
                    btnclick(item, "Weil 3 Direktmandate gewonnen wurden")
                  }
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Die Linke hat trotz des Verfehlens der 5%-Hürde ins Parlament geschafft,
        da es eine Ausnahmeregelung gibt, falls eine Partei mindestens 3
        Direktmandate bekommt.
      </p>,
    ],
    [
      <h4>26. September 2021</h4>,
      <div>
        <p>
          Bundestagswahl: Wie viele Parteien sind durch Abgeordnete im neuen
          Bundestag vertreten?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Einwohner"
            />{" "}
            <button onClick={() => negi(nant, 8)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Es gibt Abgeordnete von 8 Parteien: CDU, CSU, AfD, FDP, Grüne, SPD,
          die Linke, Südschleswigsche Wählerverband (SSW). Der SSW ist
          Vertretung der dänischen Minderheit in Schleswig-Holstein und konnte
          mit nur 55.350 Stimmen (0,1 Prozent), da für sie eine Ausnahmeregel
          gilt.
        </p>{" "}
        <p>
          Weitere Minderheiten, die diese Regel in Deutschland in Anspruch
          nehmen könnten sind Sinti und Roma, Sorben und Friesen.
        </p>
      </div>,
    ],
    [
      <h4>04. Oktober bis 08. Oktober 2021</h4>,
      <div>
        <p>
          Der Physik-Nobelpreis wird unter anderem an den Deutschen Klaus
          Hasselmann vergeben. Der Chemie-Nobelpreis geht unter anderen an den
          Deutschen Benjamin List. Wie viele deutsche haben den Nobel seit der
          Stiftung 1901 bekommen?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl Nobelpreise"
            />{" "}
            <button onClick={() => negi(nant, 84)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Insgesamt haben 84 Deutsche den Nobelpreis bisher bekommen. Andere
          Länder: Schweiz 27, Österreich 22, USA 351.
        </p>
      </div>,
    ],
    [
      <h4>07. Oktober 2021</h4>,
      <div>
        <p>
          Die ständige Impfkommission empfielt erstmals eine Auffrischung der
          Corona-Impfung. Zunächst hauptsächlich für Menschen über 70. Wie viele
          Menschen haben sich in Deutschland 2021 gegen Corona impfen lassen
          (Erstimpfungen)?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 61200000)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          61,2 Millionen Menschen haben in Deutschland 2021 eine Erstimpfung
          erhalten.
        </p>
      </div>,
    ],
    [
      <h4>09. Oktober 2021</h4>,
      <div>
        <p>
          Sebastian Kurz tritt als Österreichischer Kanzler zurück. Ihm wird
          vorgeworfen im Gegenzug für die Schaltung steuerfinanzierter Anzeigen
          die Veröffentlichung schmeichelhafter Umfragen erhalten zu haben. Im
          Dezember beendete Kurz dann mit 35 Jahren vollständig seine politische
          Karriere. In welchem Alter hatte er sein erstes politisches Staatsamt
          als Staatssekretär inne?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 25)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Sebastian Kurz wurde mit 25 Jahren Staatssekretär im Außenministerium
          Österreichs.
        </p>
      </div>,
    ],
    [
      <h4>11. Oktober 2021</h4>,
      <div>
        <p>
          Als Konsequenz aus der Bundestagswahl will die CDU ihre Parteispitze
          neu wählen. Später wird entschieden dazu erstmals die Mitglieder zu
          befragen. Wie viele Mitgliede hat die CDU insgesamt (Stand 2020)?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 399110)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Die CDU hat 399.100 Mitglieder und liegt damit knapp hinter der SPD
          mit 404305 Mitgliedern.
        </p>
      </div>,
    ],
    [
      <h4> 18. Oktober 2021</h4>,
      <div>
        <p>
          Der Chef der Bild-Zeitung, Julian Reichelt, wird aufgrund von
          Liebesbeziehungen am Arbeitsplatz entlassen. Im Januar 2001 lag die
          Print-Auflage der Bildzeitung bei 4,36 Millionen. Schätze wie hoch die
          Auflage heute ist.
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 1160000)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Die Bild-Zeitung hat eine Print-Auflage von 1,16 Millionen. Im August
          2021 ging Bild.tv an den Start, die Zeitung versucht sich zu
          digitalisieren.
        </p>
      </div>,
    ],
    [
      <h4>21. Oktober 2021</h4>,
      <div>
        <p>
          Der Schauspieler Alec Baldwin erschießt versehentlich bei Dreharbeiten
          eine Kamerafrau. Seine Frau und Mutter von 6 seiner 7 Kinder, ist
          gebürtige Amerikanerin und hatte vor einer Twitter-Kontroverse 2020
          kurioserweise bei öffentlichen Auftritten gelegentlich einen
          ausländischen Akzent, von dem auch Alec Baldwin scheinbar glaubte er
          sei echt. Welchen Akzent imitierte sie?
        </p>

        {allegleich
          ? null
          : ["Spanisch", "Italienisch", "Französisch", "Indisch"].map(
              (item, index) => (
                <div key={item}>
                  <button
                    className={
                      kati !== "undefined" &&
                      kati !== 0 &&
                      kati[gewaehltnam][1] === item
                        ? "augewbtn"
                        : null
                    }
                    onClick={() => btnclick(item, "Spanisch")}
                  >
                    {item}
                  </button>
                  <br />
                </div>
              )
            )}
      </div>,
      <p>
        Hilaria (geboren als Hillary) hat in ihrer Kindheit und Jugend
        regelmäßig mit ihren Eltern Urlaube auf Mallorca verbracht und hat damit
        ihren spanischen Akzent erklärt. Auch ihre Kinder haben spanische
        Vornamen.
      </p>,
    ],
    [
      <h4>25. Oktober 2021</h4>,
      <div>
        <p>
          Armin Laschet übergibt sein Amt als Nordrhein-westfälischer
          Ministerpräsident an Hendrik Wüst. Hendrik Wüst engagiert sich seit
          seiner Jugend ehrenamtlich in der CDU. 2000 - 2006 war er Vorsitzender
          der Jungen Union und damit auch im Landesvorstand der CDU
          Nordrhein-Westfalen. Ab 2002 auch Mitlglied im Bundesvorstand der CDU.
          Welchen Beruf übte er während dieser Zeit aus?
        </p>

        {allegleich
          ? null
          : [
              "Journalist",
              "Unternehmer",
              "Lehrer",
              "Bauingeneur",
              "Lobbyist",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Lobbyist")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Von 2000 bis 2005 arbeitete Hendrik Wüst für eine Lobbyagentur in
        Berlin. Später leitete er die Interessenvertretung
        nordrhein-westfälischer Zeitungsverleger.
      </p>,
    ],
    [
      <h4>26. Oktober 2021</h4>,
      <div>
        <p>
          Da die SPD nun stärkste Kraft im Bundestag ist, stellt sie die
          Bundestagspräsidentin. Wie heißt die neue Amtsinhaberin?
        </p>

        {allegleich
          ? null
          : [
              "Christine Lambrecht",
              "Manuela Schwesig",
              "Bärbel Bas",
              "Klara Geywitz",
              "Jessica Rosenthal",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Bärbel Bas")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Bärbel Bas wurde zur Bundestagspräsidentin gewählt.</p>,
    ],
    [
      <h4>28. Oktober 2021</h4>,
      <div>
        <p>
          Die Inflationsrate steigt auf 4,8%. Wie hoch ist das offizielle
          Inflations-Ziel der Europäischen Zentralbank?
        </p>

        {allegleich
          ? null
          : ["0%", "0,5%", "1%", "1,5%", "2%"].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "2%")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Das offizielle Inflationsziel ist unter, aber nahe an 2%.</p>,
    ],
    [
      <h4>31. Oktober 2021</h4>,
      <div>
        <p>
          Die Weltklimakonferenz beginnt in Glasgow mit 39.509 Teilnehmern aus
          192 Staaten. Welches Land ist dort mit der größten Mannschaft an
          Klimadiplomaten vertreten?
        </p>

        {allegleich
          ? null
          : [
              "Türkei",
              "Ghana",
              "Demokratische Republik Kongo",
              "Brasilien",
              "Russland",
              "Kenia",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Brasilien")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Brasilien entsendet das größte Diplomatenteam mit 479 Delegierten.
        Dahinter folgen die Türkei (376), Demokratische Republik Kongo (373),
        Ghana (337), Russland (312) und Kenia (308).
      </p>,
    ],
    [
      <h4>08. November 2021</h4>,
      <div>
        <p>
          Da Norbert Walter-Borjans nicht weitermachen will, gibt es einen neuen
          SPD-Vorsitzenden neben Saskia Esken. Wie ist sein Name?
        </p>

        {allegleich
          ? null
          : [
              "Kevin Kühnert",
              "Hubertus Heil",
              "Olaf Scholz",
              "Rolf Mützenich",
              "Lars Klingbeil",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Lars Klingbeil")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>
        Lars Klingbeil war zuvor Generalsekretär der SPD und wurde nun
        Parteivorsitzender der SPD.
      </p>,
    ],
    [
      <h4>24. November 2021</h4>,
      <div>
        <p>
          SPD, Grüne und FDP einigen sich auf einen Koalitionsvertrag. Für
          welchen Bereich sind die Grünen auf Ministerebene NICHT zuständig?
        </p>

        {allegleich
          ? null
          : [
              "Umwelt",
              "Energie",
              "Verkehr",
              "Verbraucherschutz",
              "Landwirtschaft",
              "Klimaschutz",
            ].map((item, index) => (
              <div key={item}>
                <button
                  className={
                    kati !== "undefined" &&
                    kati !== 0 &&
                    kati[gewaehltnam][1] === item
                      ? "augewbtn"
                      : null
                  }
                  onClick={() => btnclick(item, "Verkehr")}
                >
                  {item}
                </button>
                <br />
              </div>
            ))}
      </div>,
      <p>Das Verkehrsministerium ging an Volker Wissing von der FDP.</p>,
    ],
    [
      <h4> 08. Dezember 2021</h4>,
      <div>
        <p>
          Olaf Scholz wird als neuer Bundeskanzler vereidigt. Der wievielte
          Kanzler der Bundesrepublik ist Olaf Scholz?
        </p>

        {allegleich ? null : (
          <div>
            {" "}
            <input
              className="inputround"
              type="number"
              value={nant === 0 ? "" : nant}
              onChange={(e) => setNant(e.target.value)}
              placeholder="Anzahl"
            />{" "}
            <button onClick={() => negi(nant, 9)}>Absenden</button>
          </div>
        )}
      </div>,
      <div>
        <p>
          Olaf Scholz ist der 9. Bundeskanzler der Bundesrepublik Deutschland.
        </p>
      </div>,
    ],
  ];
  const karr = [
    "rrr",
    "ppp",
    "aaa",
    [
      "Persönliche Frage 1",
      "Wer hat dieses Jahr am meisten Geld verdient? 2",
      "Wer hat dieses Jahr am meisten Treibhausgase produziert? 3",
      "Wer hat sich dieses Jahr am meisten geärgert 4",

      "Wer hatte das beste Jahr 2021 5",
      "Wer hat dieses Jahr am meisten neues gelernt? 6",
      "Wer hat dieses Jahr am meisten Spaß gehabt 7",
      "Wer hat dieses Jahr anderen die meiste Freude gemacht? 8",
      "Persönliche Frage 9",
      "Persönliche Frage 10",
      "Persönliche Frage 11",
    ],
    [
      "Andere Frage 1",
      "Andere Frage 2",
      "Andere Frage 3",
      "Andere Frage 4",

      "Andere Frage 5",
      "Andere Frage 6",
      "Andere Frage 7",
      "Andere Frage 8",
      "Andere Frage 9",
      "Andere Frage 10",
      "Andere Frage 11",
    ],
  ];

  const rueck = [
    "Drücke auf Start um zu starten",
    "Wie heißt der deutsche Bundeskanzler",
    "Die wievielte Corona-welle hatten wir dieses Jahr?",
    "Frage Nummer 3",
    "Frage Nummer 4",
    "Frage Nummer 5",
    "Frage Nummer 6",
    "Frage Nummer 7",
    "Frage Nummer 8",
    "Frage Nummer 9",
    "Frage Nummer 10",
    "Frage Nummer 11",
  ];

  const pers = [
    "Persönliche Frage 1",
    "Wer hat dieses Jahr am meisten Geld verdient? 2",
    "Wer hat dieses Jahr am meisten Treibhausgase produziert? 3",
    "Wer hat sich dieses Jahr am meisten geärgert 4",

    "Wer hatte das beste Jahr 2021 5",
    "Wer hat dieses Jahr am meisten neues gelernt? 6",
    "Wer hat dieses Jahr am meisten Spaß gehabt 7",
    "Wer hat dieses Jahr anderen die meiste Freude gemacht? 8",
    "Persönliche Frage 9",
    "Persönliche Frage 10",
    "Persönliche Frage 11",
  ];

  const anders = [
    "Andere Frage 1",
    "Andere Frage 2",
    "Andere Frage 3",
    "Andere Frage 4",

    "Andere Frage 5",
    "Andere Frage 6",
    "Andere Frage 7",
    "Andere Frage 8",
    "Andere Frage 9",
    "Andere Frage 10",
    "Andere Frage 11",
  ];

  useEffect(() => {
    const unsubscribe = ref
      .collection(spielc)
      .doc("zz")
      .onSnapshot(function (doc) {
        setZahlg(doc.data().tuel);
        setKati(doc.data());
        setSplr(doc.data().spieler);
        // setRri(doc.data().rrr);
        // setPpi(doc.data().ppp);
        // setAai(doc.data().aaa);
        // alle rundenzahlen der spieler werden in items array gespeichert. Sobald alle gleich sind ist setAllegleich === true, wenn nächste frage wird spieler[0] === 1000 gestellt dann setAllegleich wieder false
        console.log(
          doc.data()[gewaehltnam],
          "doc.data().gewaehltnam",
          gewaehltnam,
          "Anzahl Fragen:",
          katarr.length
        );
        if (typeof doc.data()[gewaehltnam] !== "undefined") {
          const items = [];
          console.log(
            "doc.data().spieler",
            doc.data().spieler,
            doc.data().spieler[0]
          );
          doc.data().spieler.map((some) => items.push(doc.data()[some][0]));
          // doc.data().spieler.map(function (some) {
          //   console.log(doc.data().some, "doc.data().some", doc.data()[some]);
          //   items.push(doc.data()[some][0]);
          //   return items;
          // });

          const lose = [];
          doc.data().spieler.map((some) => lose.push(doc.data()[some][1]));
          setLosarr(lose);

          setAlleggleich(
            new Set(items).size === 1 && doc.data().spieler[0] !== 1000
          );
          console.log(new Set(items).size, items);
        }

        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  function getran() {
    // ref
    //   .collection("reihe")
    //   .doc("zz")
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       fragenzahl = doc.data().tuel;
    //       console.log(
    //         fragenzahl,
    //         "fragenzzz",
    //         katarr[kati].length,
    //         katarr[kati][fragenzahl].length,
    //         katarr[0][fragenzahl],
    //         kati
    //       );
    //       if (fragenzahl < katarr[kati].length - 1) {

    console.log(
      "wooo?",
      splr,
      "stimmts oder nei",
      splr.includes(kati[gewaehltnam][1]),
      kati[gewaehltnam][1],
      allegleich
    );

    if (splr.includes(kati[gewaehltnam][1])) {
      auswerter = [];
      kathelf = [];
      if (allegleich) {
        for (var a = 0; a < splr.length; a++) {
          auswerter.push(losarr.filter((v) => v === splr[a]).length);
          console.log(
            "auswerter",
            losarr,
            auswerter,
            Math.max(...auswerter),
            splr
          );
        }

        console.log(
          " kati[splr[auswerter.indexOf(Math.max(...auswerter))]",
          kati[splr[auswerter.indexOf(Math.max(...auswerter))]],
          kati[gewaehltnam][1],
          "kati[gewaehltnam][1]"
        );
        for (var b = 0; b < splr.length; b++) {
          if (
            kati[splr[b]][1] ===
            kati[splr[auswerter.indexOf(Math.max(...auswerter))]][1]
          ) {
            kathelf = [];
            console.log("kati.gewaehltnam", kati[splr[b]][2]);
            kathelf = kati[splr[b]];
            kathelf[2] = kati[splr[b]][2] + 1;
            ref
              .collection(spielc)
              .doc("zz")
              .update({ [splr[b]]: kathelf })
              .catch((err) => {
                console.error(err);
              });
          }
        }

        setSchaetzwin(auswerter.indexOf(Math.max(...auswerter)));
      }
    } else {
      console.log("bin im else lösung");
      for (var i = 0; i < splr.length; i++) {
        kathelf = [];
        console.log(
          "Bedingungen",
          kati[splr[i]][1] === eingloesung[1] && eingloesung[0] === zahlg,
          "oder",
          kati[splr[i]][1] === btnloesung[1] && btnloesung[0] === zahlg,
          i,
          "oderrr nah",
          kati[splr[i]][1] === winner[1]
        );
        if (
          (kati[splr[i]][1] === eingloesung[1] && eingloesung[0] === zahlg) ||
          (kati[splr[i]][1] === btnloesung[1] && btnloesung[0] === zahlg) ||
          kati[splr[i]][1] === winner[1]
        ) {
          kathelf = [];
          kathelf = kati[splr[i]];
          kathelf[2] = kati[splr[i]][2] + 1;
          ref
            .collection(spielc)
            .doc("zz")
            .update({ [splr[i]]: kathelf })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    }
    if (katarr.length - 1 > zahlg) {
      ref
        .collection(spielc)
        .doc("zz")
        .update({
          [splr[0]]: [
            1000,
            999999999999999999999999999999999999990,
            kati[splr[0]][2],
          ],
          tuel: zahlg + 1,
        })
        .then(setWinner([1002000000, 2220000000000]))
        .catch((err) => {
          console.error(err);
        });
    } else {
      ranghelper = [];
      zahlenrangarr = [];
      sortiertezahlarr = [];
      for (var c = 0; c < splr.length; c++) {
        var ht = kati[splr[c]][2];
        console.log("ht", ht, c);
        zahlenrangarr.push(ht);
        sortiertezahlarr.push(ht);
      }
      console.log("zahlenrangarr vor", zahlenrangarr);

      console.log("sortiertezahlarr", sortiertezahlarr);
      zahlenrangarr.sort(function (a, b) {
        return b - a;
      });
      console.log(zahlenrangarr, "zahlensortiert?", sortiertezahlarr);
      for (var d = 0; d < splr.length; d++) {
        var hiha = sortiertezahlarr.indexOf(zahlenrangarr[d]);
        console.log("hiha", hiha, d);
        ranghelper.push(splr[hiha]); // welchen rang jeweiliger indexname in splr erreicht hat
      }
      console.log(
        ranghelper,
        "ranghelp",
        zahlenrangarr,
        "sortiertezahlarr:",
        sortiertezahlarr
      );
      ref
        .collection(spielc)
        .doc("zz")
        .update({
          ranking: ranghelper,
        })
        .then(() => {
          setRangstate(ranghelper);
          setNochmal(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  let ranghelper = [];
  let zahlenrangarr = [];
  let sortiertezahlarr = [];
  let chathelper = [];
  function negi(antw, lzahl) {
    console.log("wasis lzahl", lzahl);
    const needle = lzahl;
    setEingloesung([zahlg, lzahl]);
    chathelper = losarr;
    chathelper.push(parseInt(antw));
    const closest = chathelper.reduce((a, b) => {
      return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
    });
    console.log(closest, "closest");
    setWinner([zahlg, closest]);
    chathelper = [];
    console.log("number antwort", nant, winner[1], closest, losarr);
    setNant(0);

    ref
      .collection(spielc)
      .doc("zz")
      .update({ [gewaehltnam]: [zahlg, parseInt(antw), kati[gewaehltnam][2]] })
      .catch((err) => {
        console.error(err);
      });
  }

  let wahlindex;

  function spielerClick(spname) {
    // let helperabst = [];
    // let abgearr = [];
    // wahlindex = kati.spieler.indexOf(spname);
    // helperabst = kati.abstimmung;
    // abgearr = kati.abgestimmt;
    // if (~wahlindex) {
    //   helperabst[wahlindex] = spname;
    //   abgearr[wahlindex] = zahlg;
    //   console.log(
    //     "helperabst[wahlindex]",
    //     helperabst[wahlindex],
    //     kati.abstimmung[wahlindex]
    //   );
    // }
    ref
      .collection(spielc)
      .doc("zz")
      .update({ [gewaehltnam]: [zahlg, spname, kati[gewaehltnam][2]] })
      .catch((err) => {
        console.error(err);
      });
  }
  let auswerter = [];
  let kathelf = [];
  function spienam() {
    auswerter = [];
    kathelf = [];
    if (allegleich) {
      for (var a = 0; a < splr.length; a++) {
        auswerter.push(losarr.filter((v) => v === splr[a]).length);
        console.log(
          "auswerter",
          losarr,
          auswerter,
          Math.max(...auswerter),
          splr
        );
      }

      console.log(
        " kati[splr[auswerter.indexOf(Math.max(...auswerter))]",
        kati[splr[auswerter.indexOf(Math.max(...auswerter))]],
        kati[gewaehltnam][1],
        "kati[gewaehltnam][1]"
      );
    }

    setSchaetzwin(auswerter.indexOf(Math.max(...auswerter)));
  }

  //   setBtnloesung([zahlg, correct]);
  // ref
  //   .collection(spielc)
  //   .doc("zz")
  //   .update({
  //     abstimmung: helperabst,
  //     abgestimmt: abgearr,
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //   });

  function btnclick(answe, correct) {
    ref
      .collection(spielc)
      .doc("zz")
      .update({ [gewaehltnam]: [zahlg, answe, kati[gewaehltnam][2]] })
      .catch((err) => {
        console.error(err);
      });
    setBtnloesung([zahlg, correct]);
  }

  function nochfun() {
    if (kati.tuel > katarr.length - 2) {
      splr.map((item, index) =>
        ref
          .collection(spielc)
          .doc("zz")
          .update({ [item]: [parseInt(index) + 11, 0, 0], tuel: 0 })
          .then(() => {
            console.log(setNochmal(false));
          })
          .catch((err) => {
            console.error(err);
          })
      );
    } else {
      setNochmal(false);
    }
  }

  return (
    <Fragment>
      {loading ? (
        <p>Lädt...</p>
      ) : (
        <div className="centering textcenter">
          <div className="margin-rund">
            {nochmal ? (
              <div>
                <p className="namensschrift">Ergebnis:</p>{" "}
                {rangstate.map((item, index) => (
                  <div key={item}>
                    {index === 0 && gewaehltnam === item ? (
                      <p className="datumschrift">
                        Glückwunsch <span className="bkrreg">{item}</span>! Du
                        hast mit {kati[rangstate[0]][2]} Punkten gewonnen!
                      </p>
                    ) : index === 0 && gewaehltnam !== item ? (
                      <p className="frageschrift">
                        <span className="bkrbl">{item}</span> hat mit{" "}
                        {kati[rangstate[0]][2]} Punkten gewonnen!
                      </p>
                    ) : (
                      <p className="frageschrift">
                        {index + 1}. Platz <span className="bkrbl">{item}</span>{" "}
                        ({kati[rangstate[index]][2]} Punkte)
                      </p>
                    )}
                  </div>
                ))}
                <button onClick={nochfun}>Neustart</button>
              </div>
            ) : (
              <div>
                <p className="namensschrift">{gewaehltnam}</p>

                <div className="datumschrift">{katarr[zahlg][0]}</div>
                <div className="frageschrift">{katarr[zahlg][1]}</div>
                {splr.map((item, index) => (
                  <div key={item}>
                    {allegleich ? (
                      <p className="loesungsnamen">
                        <span
                          className={
                            (kati[splr[index]][1] === eingloesung[1] &&
                              eingloesung[0] === zahlg) ||
                            (kati[splr[index]][1] === btnloesung[1] &&
                              btnloesung[0] === zahlg)
                              ? "richtig"
                              : kati[splr[index]][1] === winner[1]
                              ? "nah"
                              : null
                          }
                        >
                          Lösung von {item}: {kati[splr[index]][1]}
                        </span>
                      </p>
                    ) : (
                      <div>
                        {" "}
                        {typeof kati.gewaehltnam !== "undefined" &&
                        kati[splr[index]][0] === zahlg ? (
                          <p>{item} fertig</p>
                        ) : null}
                      </div>
                    )}
                  </div>
                ))}
                {allegleich ? (
                  <div className="aufloesung">
                    <p>Auflösung:</p>
                    {katarr[zahlg][2]}
                    {splr.includes(kati[gewaehltnam][1]) ? (
                      <div>
                        <button onClick={() => spienam()}>Ergebnis</button>
                        <p>wasss {splr[schaetzwin]}</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {/* <p>Winner {winner[1]}</p> */}
                <p className="punktestand">{kati[gewaehltnam][2]}</p>
                <button onClick={getran}>Nächste Frage</button>
              </div>
            )}
          </div>{" "}
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function Datenschutz() {
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/`);
  }
  return (
    <Fragment>
      <div className="centering">
        <div className="margin-rund textcenter frageschrift">
          <p>Datenschutz</p>
          <p>Speicherung persönlicher Daten</p>
          <p>
            Daten die Sie uns zur Verfügung stellen, etwa wenn sie uns über
            Telefon oder E-Mail kontaktieren, werden sicher verwahrt, und nur
            für den unmittelbaren Zweck Ihrer Anfrage bearbeitet. <br />
            Daten, die Sie aktiv über die Website eingeben (Eingabe Code,
            Profilerstellung usw.), werden in einer Datenbank gespeichert. Dies
            hat vor allem technische Gründe. Damit z.B. von mehreren Geräten
            über den gleichen Code auf die gleichen Profile und Chats
            zugegriffen werden kann, und dass eingegebene "Unterhaltungen" nicht
            einfach wieder verschwinden, wenn man die Seite neu lädt. Zudem
            werden diese Daten auch zur Verbesserung und Weiterentwicklung des
            Spiels verwendet.
            <br /> Eine Personalisierung über den von Ihnen erworbenen
            beziehungsweise verwendeten Code findet nicht statt, und ist uns
            auch nicht möglich. Die eingegebenen Codes können lediglich
            Produktionschargen/-Auflagen zugeordnet werden, nicht aber Ihrer
            spezifischen Bestellung des Spiels. <br />
            Geben Sie keine persönlichen Daten über diese Website ein, da die
            Eingaben wie oben beschrieben für die Funktionalität des Spiels und
            zur Verbesserung des Spiels unverschlüsselt in einer Datenbank
            gespeichert werden.
            <br />
            Diese Daten werden nicht an Dritte weitergegeben (außer wir sind
            etwa bei Rechtsverstößen Behörden gegenüber zur Auskunft
            verpflichtet).
          </p>

          <p>
            Wenn Sie die vollständige Löschung Ihrer Daten und/oder der Ihrer
            Eingaben auf dieser Website wünschen, reicht eine formlose E-Mail an
            info[at]manachlux.com
          </p>
          <p>
            Grundsätzlich haben Sie folgende Rechte: Recht auf Berichtigung,
            Recht auf Löschung, Recht auf Einschränkung der Verarbeitung, Recht
            auf Benachrichtigung, Recht auf Datenübertragbarkeit,
            Widerspruchsrecht, und das Recht nicht von Profiling betroffen zu
            sein (Artikel 16 - 22 DSGVO).
          </p>
          <p>
            Auf dieser Website wird Google Fonts verwendet. Deshalb speichert
            Google Font ist nach Angaben von Google vollkommen getrennt von
            anderen Google Diensten, laut Google werden personenbezogene Daten
            dabei nicht verarbeitet oder erfasst. Google Font dateien werden auf
            Ihrem Rechner gespeichert um die Ladezeiten von Webseiten zu
            verringern.
          </p>
          <p></p>
          <div className="textcenter">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>{" "}
      </div>
    </Fragment>
  );
}

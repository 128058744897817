import React, { useState } from "react";
import AppContext from "./AppContext";

const JahrContext = ({ children }) => {
  const [zahlg, setZahlg] = useState(0);
  const [kati, setKati] = useState(0);
  const [rri, setRri] = useState(0);
  const [ppi, setPpi] = useState(0);
  const [aai, setAai] = useState(0);
  const [splr, setSplr] = useState(["Spieler 1", "Spieler 2"]);
  const [pnam, setPnam] = useState("");
  const [gewaehltnam, setGewaehltnam] = useState("jmd");
  const [spielc, setSpielc] = useState("");
  const [spielerarr, setSpielerarr] = useState([]);
  const [payed, setPayed] = useState(false);
  const [eco, setEco] = useState(12345);
  const [ant, setAnt] = useState("");
  const context = {
    zahlg,
    setZahlg,
    ppi,
    setPpi,
    rri,
    setRri,
    kati,
    setKati,
    aai,
    setAai,
    splr,
    setSplr,
    pnam,
    setPnam,
    gewaehltnam,
    setGewaehltnam,
    spielc,
    setSpielc,
    spielerarr,
    setSpielerarr,
    payed,
    setPayed,
    eco,
    setEco,
    ant,
    setAnt,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
export default JahrContext;

import React, { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import gruppe2 from "../gruppe2.jpg";
import Kauf from "../components/Kauf";
import Ober from "./Ober";
import Landing from "./Landing";
export default function Start() {
  const [aufS, setAufS] = useState(false);
  const [aufK, setAufK] = useState(false);
  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }
  function clickQ() {
    setAufK(false);
    setAufS(!aufS);
  }

  function clickK() {
    setAufK(!aufK);
    setAufS(false);
  }
  return (
    <Fragment>
      <div className="margin-rund">
        <div className="centering textcenter">
          <div>
            <img className="img-width" src={gruppe2} alt="Fahrer mit Paketen" />
          </div>
          <p className="datumschrift">
            Der spielerische Jahresrückblick auf das Jahr 2021 in Quiz-Form.
          </p>
          <button onClick={clickQ}>Quiz Starten</button>{" "}
          {/* <button onClick={clickK}>Zugangscode Kaufen</button> */}
          {aufK ? <Kauf /> : null}
          {aufS ? <Ober /> : null}
          <Landing />
          <p>
            <span onClick={handleClick} className="imschrift">
              Impressum
            </span>{" "}
            <span onClick={clickDS} className="imschrift">
              Datenschutz
            </span>
          </p>{" "}
        </div>
      </div>{" "}
    </Fragment>
  );
}

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import JahrContext from "./provider/JahrContext";
import "./App.css";
import Main from "./pages/main";
import Spiel from "./pages/spiel";
import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";

function App() {
  return (
    <>
      <JahrContext>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/:spiel" element={<Spiel />} />
            <Route exact path="/datenschutz" element={<Datenschutz />} />
          </Routes>
        </Router>
      </JahrContext>
    </>
  );
}

export default App;

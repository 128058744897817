// import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB2ePhKno2q8IeR7Tc1nKQEp6ihcGq-LFY",
  authDomain: "jahres-6d41e.firebaseapp.com",
  projectId: "jahres-6d41e",
  storageBucket: "jahres-6d41e.appspot.com",
  messagingSenderId: "587184755615",
  appId: "1:587184755615:web:1c5cd5c15d1605742ce3aa",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
export default firebase;

import React, { Fragment, useState, useContext } from "react";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import { useNavigate } from "react-router-dom";

export default function Einrichten() {
  const { spielc, setSpielc, spielerarr, setSpielerarr } =
    useContext(AppContext);
  const ref = firebase.firestore();
  const navigate = useNavigate();
  const [antwort, setAntwort] = useState("");
  const [co, setCo] = useState("123");
  const [corrcd, setCorrcd] = useState(false);
  const [rei, setRei] = useState(false);

  const [abgearr, setAbgearr] = useState([]);
  function coein(cod) {
    ref
      .collection(`${cod}`)
      .doc("purchase")
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().active === false) {
            ref
              .collection(`${cod}`)
              .doc("zz")
              .update({ active: true })
              .catch((err) => {
                console.error(err);
              })
              .then(console.log("codeaktivirt"));
            setCorrcd(true);
          } else {
            console.log("code ist schon aktiv datumcheck??");
            setCorrcd(true);
          }
        } else {
          console.log("Ungültiger Codd!!!");
        }
      });
  }

  function weit() {
    var result = "";
    var characters = "abcdefghijkmnpqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    ref
      .collection(`${co}`)
      .doc("zz")
      .update({ spieler: spielerarr })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        setRei(true);

        ref
          .collection(`${result}`)
          .doc("zz")
          .set({
            game: result,
            spieler: spielerarr,
            tuel: 0,
            ranking: spielerarr,
          })
          .catch((err) => {
            console.error(err);
          })
          .then(() => {
            setSpielc(result);
          });

        spielerarr.map((item, index) =>
          ref
            .collection(`${result}`)
            .doc("zz")
            .update({ [item]: [parseInt(index) + 11, 0, 0] })
            .catch((err) => {
              console.error(err);
            })
            .then(() => {
              console.log(
                index === spielerarr.lenght - 1,
                "index === auswahl.lenght - 1"
              );
            })
        );
      });
  }

  function hinzu(name) {
    setSpielerarr((oldArray) => [...oldArray, name]);

    setAbgearr((oldArray) => [...oldArray, 0]);

    setAntwort("");
  }

  function clickSpiel() {
    navigate(`/${spielc}`);
  }
  return (
    <Fragment>
      {rei ? (
        <div>
          <p className="datumschrift">
            Du hast ein neues Spiel erstellt. Deine Mitspieler kommen zu Deinem
            Spiel, indem sie den Code <span className="bkrreg">{spielc}</span>{" "}
            auf ihrem Gerät auf der Hauptseite eingeben, oder direkt zur URL{" "}
            <span className="bkrreg">eichiquiz.de/{spielc}</span> gehen.
          </p>{" "}
          <p className="datumschrift">Viel Spaß!</p>{" "}
          <button onClick={clickSpiel}>zum Spiel</button>
        </div>
      ) : (
        <div>
          <div>
            {/* <p className="frageschrift">Starte ein neues Spiel</p>{" "} */}
          </div>{" "}
          {!corrcd ? (
            <div>
              {/* <input
                className="inputround"
                type="number"
                value={co}
                onChange={(e) => setCo(e.target.value)}
                placeholder="Zugangscode"
              />{" "} */}
              <button onClick={() => coein(co)}>Starte ein neues Spiel</button>
            </div>
          ) : (
            <div>
              <p className="datumschrift">Wer spielt?</p>{" "}
              <div>
                {" "}
                <input
                  className="inputround"
                  type="text"
                  value={antwort}
                  onChange={(e) => setAntwort(e.target.value)}
                  placeholder="Spielername hinzufügen"
                />{" "}
                <button onClick={() => hinzu(antwort)}>Absenden</button>
              </div>
              <div>
                <p className="datumschrift">
                  Anzahl Spieler: {spielerarr.length}
                </p>
                {spielerarr.map((item, index) => (
                  <div key={item} className="namensschrift">
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <button onClick={() => weit()}>Weiter</button>{" "}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import frage4a from "../frage4.png";
import frage4b from "../frage1.png";
import gruppe3 from "../gruppe4.png";
import gruppe1 from "../gruppe1.jpg";
export default function Landing() {
  const [aufS, setAufS] = useState(false);
  const [aufK, setAufK] = useState(false);
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="margin-rund">
        <div className="centering textcenter">
          <div className="abstand1"></div>
          <p className="frageschrift">
            Für 2 oder mehr Spieler. Die Fragen und Antworteingaben macht Ihr
            jeweils an Euren Handys (Internetverbindung erforderlich). Der
            Spieler mit den meisten Punkten gewinnt!
          </p>
          <p className="datumschrift">
            Das Quiz führt Euch chronologisch durch das Jahr 2021, mit Fragen zu
            bunt gemischten Themen aus Gesellschaft, (Pop-)Kultur, Politik,
            Skurrilem und Persönlichem.
          </p>
          <p className="datumschrift">Spieldauer ca. 1 - 1,5 Stunden.</p>
          <div className="abstand1"></div>
          <img className="img-breit" src={gruppe3} alt="Fahrer mit Paketen" />
          <div className="abstand1"></div>
          <p className="datumschrift">
            In geselliger Runde oder auch kontaktlos z.B. in einer
            Videokonferenz spielbar.
          </p>{" "}
          <div className="abstand1"></div>
          <div>
            <img className="h50p" src={frage4a} alt="Fahrer mit Paketen" />
          </div>
          <div>
            {" "}
            <div className="abstand1"></div>
            <img className="img-breit" src={gruppe1} alt="Fahrer mit Paketen" />
          </div>
          <div className="abstand1"></div>
          <div>
            <img className="h50p" src={frage4b} alt="Fahrer mit Paketen" />
          </div>
        </div>
      </div>{" "}
    </Fragment>
  );
}

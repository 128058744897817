import React, { Fragment, useState, useContext } from "react";

import MailZahl from "./MailZahl";
import AppContext from "../provider/AppContext";
export default function Kauf() {
  const { payed, eco } = useContext(AppContext);
  const [antwort, setAntwort] = useState("");

  return (
    <Fragment>
      <div className="centering">
        <div className="margin-rund">
          <p className="frageschrift">
            Du hast noch keinen Zugangscode? Für einmalig{" "}
            <span className="bkrbl">2,95 Euro</span> kannst Du einen bis
            05.01.2021 gültigen Zugangscode kaufen. Damit kannst Du beliebig
            oft, mit so vielen Freunden wie Du willst das Quiz Spielen. Spielen
            die Du erstellt hast können Deine Freunde kostenlos beitreten.
          </p>
          <MailZahl />
          <p>
            {eco !== 12345 ? (
              <div className="datumschrift">
                <p className="bkrreg">
                  Kauf erfolgreich! Dein Zugangscode lautet: {eco}
                </p>{" "}
                <p className="bkrbl">
                  Um direkt ein Spiel mit Deinen Freunden zu starten, gib diesen
                  Code oben ein. Du erhälst später eine Bestätigungs-E-Mail.
                  Auch in Deiner Zahlungsbestätigung von Paypal steht der Code.
                  Notiere Dir aber zur Sicherheit schon mal den Code.{" "}
                </p>
              </div>
            ) : null}
          </p>{" "}
        </div>
      </div>
    </Fragment>
  );
}

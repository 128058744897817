import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../provider/AppContext";
// import Ober from "../components/Ober";
import Reihe from "../components/Reihe";
import firebase from "../firebase";

export default function Spiel() {
  // const pathProp = location.pathname.split("/")[1];
  const { pnam, setPnam, setGewaehltnam, setSpielc } = useContext(AppContext);
  const [auswahl, setAuswahl] = useState([]);
  const [rai, setRai] = useState(false);

  const location = useLocation();
  const ref = firebase.firestore();
  //  const useName = () => {};

  // useName();

  // import { useLocation } from 'react-router-dom';

  // custom hook to get the current pathname in React

  // const usePathname = () => {
  // const location = useLocation();
  //  return location.pathname;
  // }

  useEffect(() => {
    setSpielc(location.pathname.split("/")[1]);
    ref
      .collection(location.pathname.split("/")[1])
      .doc("zz")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPnam(location.pathname.split("/")[1]);
          setAuswahl(doc.data().spieler);
          console.log("Code exxxxistett");
        } else {
          console.log("Spiel existiert nicht!!");
        }
      });
  }, [location]);

  function nameclick(hier) {
    // var result = 0;
    // var characters = "123456789";
    // var charactersLength = characters.length;
    // for (var i = 0; i < 3; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    console.log("Wasspas", pnam);

    setGewaehltnam(hier);
    setRai(true);
  }

  return (
    <Fragment>
      {rai ? (
        <Reihe />
      ) : (
        <div className="centering">
          <div className="margin-rund">
            <p className="datumschrift">
              Spiel: <span className="bkrreg">/{pnam}</span>
            </p>
            <p className="frageschrift">Wähle Deinen Namen:</p>
            {auswahl.map((item, index) => (
              <div key={item}>
                <button onClick={() => nameclick(item)}>{item}</button>
              </div>
            ))}
          </div>{" "}
        </div>
      )}
    </Fragment>
  );
}

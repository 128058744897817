import React, { useState, Fragment, useContext } from "react";
import AppContext from "../provider/AppContext";
import Paypal from "../components/Paypal";

export default function MailZahl() {
  const { ant, setAnt } = useContext(AppContext);
  const [mai, setMai] = useState(false);
  const [weiter, setWeiter] = useState(false);

  const [valida, setValida] = useState("");

  function valf() {
    if (ant === "" || !(ant.includes("@") && ant.includes("."))) {
      setValida(
        "Bitte gib eine gültige Email-Adresse an. An diese wird nach dem Kauf Dein Aktivierungscode gesendet."
      );
    } else {
      setValida("");
      setMai(true);
    }
  }

  return (
    <Fragment>
      <div>
        {mai ? (
          <div>
            <p>
              <span className="frageschrift">Deine E-Mail: {ant} </span>
              <button onClick={() => setMai(false)}> Zurück</button>
            </p>
            <div className="textcenter">
              <div className="paypalwidth">
                <Paypal eno={ant} />
              </div>
            </div>
          </div>
        ) : weiter ? (
          <div>
            <p className="frageschrift">
              Gib hier Deine E-Mail ein: an diese E-Mail wird Dir später der
              Code und die Rechnung geschickt:
            </p>
            <input
              className="inputround"
              type="text"
              value={ant}
              onChange={(e) => setAnt(e.target.value)}
              placeholder="Deine Email"
            />{" "}
            <button onClick={valf}>Weiter zum Bezahlen</button>
            <p className="valii">{valida}</p>
          </div>
        ) : (
          <button onClick={() => setWeiter(true)}>Zum Kauf</button>
        )}
      </div>
    </Fragment>
  );
}

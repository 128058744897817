import React, { Fragment, useState, useContext } from "react";
import AppContext from "../provider/AppContext";
import { useNavigate } from "react-router-dom";
import Einrichten from "../components/Einrichten";

export default function Ober() {
  const { payed, eco } = useContext(AppContext);
  const [antwort, setAntwort] = useState("");
  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  function clickSpiel() {
    navigate(`/${antwort}`);
  }

  return (
    <Fragment>
      <div className="centering">
        <div className="margin-rund">
          <Einrichten />
          <p className="datumschrift">...oder</p>
          <p className="frageschrift">
            Gib den 4-stelligen Spielcode ein um einem bereits bestehenden Spiel
            beizutreten...
          </p>
          <div>
            <input
              className="inputround"
              type="text"
              value={antwort}
              onChange={(e) => setAntwort(e.target.value)}
              placeholder="4-Stelliger Spielcode"
            />{" "}
            <button onClick={clickSpiel}>zum Spiel</button>
          </div>{" "}
        </div>
      </div>{" "}
    </Fragment>
  );
}
